<template lang="">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="main-timeline">
          <div class="timeline">
            <div class="timeline-icon">
              <span class="year">
                <img
                  src="/images/forge.png"
                  class="w-75 greyblock"
                  alt="iconeforge"
                />
              </span>
            </div>
            <div class="timeline-content">
              <h3 class="title">CRÉATION</h3>
              <p class="description">
                Conception et fabrication d’ouvrages en acier, alu et Inox sur
                mesure (portails, garde-corps, verrières, escaliers).
              </p>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-icon">
              <span class="year">
                <img
                  src="/images/logo_marquises.png"
                  alt="iconeportail"
                  class="w-75 greyblock"
                />
              </span>
            </div>
            <div class="timeline-content">
              <h3 class="title">PROTECTION SOLAIRE</h3>
              <p class="description">
                Déploiement de solutions pour la protection solaire comme les
                pergolas bio-climatiques et stores.<br />
                <a
                  href="/catalogues/Marquises2022.pdf"
                  type="application/pdf"
                  target="_blank"
                  ><span class="under"
                    >Catalogue MARQUISES
                    <font-awesome-icon
                      :icon="['fas', 'book']"
                      size="1x"
                    ></font-awesome-icon></span
                ></a>
              </p>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-icon">
              <span class="year">
                <img
                  src="/images/Symbol_PROFERM.png"
                  alt="iconeportail"
                  class="w-75 greyblock"
                />
              </span>
            </div>
            <div class="timeline-content">
              <h3 class="title">ISOLATION</h3>
              <p class="description">
                Pose de menuiseries en aluminium, PVC, hybrides et textural.<br />
                <a
                  href="/catalogues/Proferm2021.pdf"
                  type="application/pdf"
                  target="_blank"
                  ><span class="under"
                    >Catalogue PROFERM
                    <font-awesome-icon
                      :icon="['fas', 'book']"
                      size="1x"
                    ></font-awesome-icon></span
                ></a>
              </p>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-icon">
              <span class="year">
                <img
                  src="/images/gate.png"
                  alt="iconeproferm"
                  class="w-75 greyblock"
                />
              </span>
            </div>
            <div class="timeline-content">
              <h3 class="title">EVENTAIL DE PRODUITS PROPOSÉS</h3>
              <p class="description">
                Installation de volets roulants, battants, brise vue,
                moustiquaires, stores, pergola bio-climatique, carport,
                clôtures, porte de garage, rideaux métalliques.
              </p>
            </div>
          </div>
          <div class="timeline">
            <div class="timeline-icon">
              <span class="year">
                <img
                  src="/images/Symbol_CAME.png"
                  class="w-75 greyblock"
                  alt="iconecame"
                />
              </span>
            </div>
            <div class="timeline-content">
              <h3 class="title">DOMOTIQUE</h3>
              <p class="description">
                Installation, réparation et entretien d’automatismes toutes
                marques. Solutions pour vos portails, volets et autres
                installations. <br />
                <a href="/catalogues/Came2022.pdf" type="application/pdf" target="_blank">
                  <span class="under"
                    >Catalogue CAME
                    <font-awesome-icon
                      :icon="['fas', 'book']"
                      size="1x"
                    ></font-awesome-icon></span
                ></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Timeline",
};
</script>
<style lang="css">
.under {
  text-decoration: underline white;
}

.container {
  padding-bottom: 20px;
}
.main-timeline {
  overflow: hidden;
  position: relative;
}
.main-timeline .timeline {
  position: relative;
  margin-top: -75px;
}
.main-timeline .timeline:first-child {
  margin-top: 0;
}

.main-timeline .timeline:nth-child(2n) {
  margin-top: -79px;
}

.main-timeline .timeline:nth-child(3n) {
  margin-top: -92px;
}

.main-timeline .timeline:nth-child(4n) {
  margin-top: -107px;
}

.main-timeline .timeline:nth-child(5n) {
  margin-top: -120px;
}

.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline .timeline:before {
  content: "";
  width: 100%;
  height: 100%;
  box-shadow: -8px 0 5px -5px rgba(0, 0, 0, 0.5) inset;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
.main-timeline .timeline-icon {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  border: 25px solid transparent;
  border-top-color: #c29333;
  border-right-color: #c29333;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  transform: rotate(45deg);
}
.main-timeline .year {
  display: block;
  width: 110px;
  height: 110px;
  line-height: 110px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  margin: auto;
  font-size: 30px;
  font-weight: bold;
  color: #c29333;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: rotate(-45deg);
}
.main-timeline .timeline-content {
  width: 35%;
  float: right;
  background: #c29333;
  padding: 30px 20px;
  margin: 50px 0;
  z-index: 1;
  position: relative;
}
.main-timeline .timeline-content:before {
  content: "";
  width: 20%;
  height: 15px;
  background: #c29333;
  position: absolute;
  top: 50%;
  left: -20%;
  z-index: -1;
  transform: translateY(-50%);
}
.main-timeline .title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 0 0 10px 0;
}
.main-timeline .description {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  margin: 0;
}
.main-timeline .timeline:nth-child(2n):before {
  box-shadow: 8px 0 5px -5px rgba(0, 0, 0, 0.5) inset;
}
.main-timeline .timeline:nth-child(2n) .timeline-icon {
  transform: rotate(-135deg);
}
.main-timeline .timeline:nth-child(2n) .year {
  transform: rotate(135deg);
}
.main-timeline .timeline:nth-child(2n) .timeline-content {
  float: left;
}
.main-timeline .timeline:nth-child(2n) .timeline-content:before {
  left: auto;
  right: -20%;
}
.main-timeline .timeline:nth-child(2n) .timeline-icon {
  border-top-color: #9ca2a5;
  border-right-color: #9ca2a5;
}
.main-timeline .timeline:nth-child(2n) .year {
  color: #9ca2a5;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .timeline-content:before {
  background: #9ca2a5;
}
.main-timeline .timeline:nth-child(3n) .timeline-icon {
  border-top-color: #8a4221;
  border-right-color: #8a4221;
}
.main-timeline .timeline:nth-child(3n) .year {
  color: #8a4221;
}
.main-timeline .timeline:nth-child(3n) .timeline-content,
.main-timeline .timeline:nth-child(3n) .timeline-content:before {
  background: #8a4221;
}
.main-timeline .timeline:nth-child(4n) .timeline-icon {
  border-top-color: #105572;
  border-right-color: #105572;
}
.main-timeline .timeline:nth-child(4n) .year {
  color: #105572;
}
.main-timeline .timeline:nth-child(4n) .timeline-content,
.main-timeline .timeline:nth-child(4n) .timeline-content:before {
  background: #105572;
}

@media screen and (max-width: 1399px) {
  .main-timeline .timeline:nth-child(2n) {
    margin-top: -103px;
  }
  .main-timeline .timeline:nth-child(3n) {
    margin-top: -102px;
  }
  .main-timeline .timeline:nth-child(4n) {
    margin-top: -118px;
  }
  .main-timeline .timeline:nth-child(5n) {
    margin-top: -130px;
  }
  .main-timeline .timeline {
    margin-top: -108px;
  }
  .main-timeline .timeline-content:before {
    left: -18%;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    right: -18%;
  }
}
@media screen and (max-width: 1199px) {
  .main-timeline .timeline:nth-child(2n) {
    margin-top: -120px;
  }

  .main-timeline .timeline:nth-child(3n) {
    margin-top: -118px;
  }

  .main-timeline .timeline:nth-child(4n) {
    margin-top: -120px;
  }

  .main-timeline .timeline:nth-child(5n) {
    margin-top: -142px;
  }

  .main-timeline .timeline {
    margin-top: -108px;
  }
  .main-timeline .timeline-content:before {
    left: -18%;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    right: -18%;
  }
}

@media screen and (max-width: 990px) {
  .main-timeline .timeline:nth-child(2n) {
    margin-top: -155px;
  }
  .main-timeline .timeline:nth-child(3n) {
    margin-top: -143px;
  }
  .main-timeline .timeline:nth-child(4n) {
    margin-top: -188px;
  }
  .main-timeline .timeline:nth-child(5n) {
    margin-top: -212px;
  }

  .main-timeline .timeline {
    margin-top: -160px;
  }
  .main-timeline .timeline-content:before {
    left: -2%;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    right: -2%;
  }
}
@media screen and (max-width: 767px) {
  .main-timeline .timeline:nth-child(2n) {
    margin-top: 0;
  }

  .main-timeline .timeline:nth-child(3n) {
    margin-top: 0;
  }

  .main-timeline .timeline:nth-child(5n) {
    margin-top: inherit;
  }

  .main-timeline .timeline:nth-child(4n) {
    margin-top: 0;
  }
  .main-timeline .timeline {
    margin-top: 0;
    overflow: hidden;
  }
  .main-timeline .timeline:before,
  .main-timeline .timeline:nth-child(2n):before {
    box-shadow: none;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(2n) .timeline-icon {
    margin-top: -30px;
    margin-bottom: 20px;
    position: relative;
    transform: rotate(135deg);
  }
  .main-timeline .year,
  .main-timeline .timeline:nth-child(2n) .year {
    transform: rotate(-135deg);
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    width: 100%;
    float: none;
    border-radius: 0 0 20px 20px;
    text-align: center;
    padding: 25px 20px;
    margin: 0 auto;
  }
  .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    width: 15px;
    height: 25px;
    position: absolute;
    top: -22px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 0);
  }
}
</style>
