<template lang="">
    <Timeline/>
</template>
<script>
import Timeline from '/src/components/Timeline.vue'
export default {
    name: 'Services',
    components: {
    Timeline,
  },
  metaInfo: {
    title: "Nos Services",
    titleTemplate: "%s | LEFLOCH Métal Concept",
  },
}
</script>
<style lang="">
    
</style>